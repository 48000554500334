import React, { useEffect } from "react"
import styled from "styled-components"

import P from "../components/atoms/P"
import Seo from "../components/seo"

import {
  checkIfGtmScriptLoaded,
  isGatsbyGdprCookieActive,
} from "../utils/utils"

const Center = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

function createTimeout() {
  return setTimeout(() => {
    window.location.href = "https://buy.stripe.com/28o9Bn4DA7wi3ok145"
  }, 500)
}

const Reserve = () => {
  useEffect(() => {
    let timeout

    if (
      !isGatsbyGdprCookieActive() ||
      !checkIfGtmScriptLoaded(() => (timeout = createTimeout()))
    )
      timeout = createTimeout()

    return () => clearTimeout(timeout)
  }, [])

  return (
    <>
      <Seo title="Reserve" />
      <Center>
        <P size="regular" color="text">
          You're being redirected now. Thank you!
        </P>
      </Center>
    </>
  )
}

export default Reserve

export const isLocalLink = path => {
  return (
    path &&
    !path.startsWith("http://") &&
    !path.startsWith("https://") &&
    !path.startsWith("//")
  )
}

export function getCookieValue(name) {
  const cookies = document.cookie.split("; ")
  for (let i = 0; i < cookies.length; i++) {
    const [cookieName, cookieValue] = cookies[i].split("=")
    if (cookieName === name) {
      return decodeURIComponent(cookieValue)
    }
  }
  return null
}

export function isGatsbyGdprCookieActive() {
  debugger
  const gatsbyGdprCookie = getCookieValue("gatsby-gdpr") === "true"
  return gatsbyGdprCookie
}

export function checkScriptLoaded(scriptSrc, callback) {
  var script = document.querySelector('script[src="' + scriptSrc + '"]')
  if (script) {
    if (!script.readyState || script.readyState === "complete") {
      setTimeout(() => callback())
      return true
    } else {
      script.addEventListener("load", callback)
      return true
    }
  } else {
    return false
  }
}

export function checkIfGtmScriptLoaded(callback) {
  return checkScriptLoaded(
    "https://www.googletagmanager.com/gtm.js?id=GTM-5B4BN4V",
    callback
  )
}
